import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";
import StaticPageContent from "components/StaticPageContent";

const PrivacyPolicy = ({ data }) => {
  const content = data?.privacyPolicy?.content;
  const seo = data?.privacyPolicy?.seo;

  return (
    <Layout
      seo={{
        title: seo?.title,
        description: seo?.metaDesc || "",
      }}
    >
      <StaticPageHeader
        title="Polityka Prywatności"
        subTitle="Informacje podstawowe dotyczące przetwarzania danych osobowych"
      />
      <StaticPageContent content={content} />
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query {
    privacyPolicy: wpPage(id: { eq: "cG9zdDoxNA==" }) {
      content
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
