import "./styles.scss";

import React from "react";
import Button from "components/Button";

const mainClass = "static-page-content";

const StaticPageContent = ({ content }) => {
  return (
    <section className={mainClass}>
      <div
        className="container container--small"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <div className={`${mainClass}__aside`}>
        <Button variant="white" href="/">
          Powrót
        </Button>
      </div>
    </section>
  );
};

export default StaticPageContent;
