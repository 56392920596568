import "./styles.scss";

import React from "react";

const mainClass = "static-page-header";

const StaticPageHeader = ({ title, subTitle, text }) => {
  return (
    <div className={mainClass}>
      <div className="container container--small">
        <h1>{title}</h1>
        {!!subTitle && <h4>{subTitle}</h4>}
        {!!text && <p>{text}</p>}
      </div>
    </div>
  );
};

export default StaticPageHeader;
